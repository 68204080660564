interface FeatureToggle {
  created_at: string;
  description: string;
  enabled: boolean;
  key: keyof NormalizedFeatureToggles;
  name: string;
  updated_at: string;
}

export interface FeatureToggleList {
  count: number;
  rows: FeatureToggle[];
}

export type AdsToggleKey =
  | 'showStoreMainInlineCarouselBanner'
  | 'showGlobalStickyHeaderBannerTextButton'
  | 'showGlobalStickyBottomBannerMobile'
  | 'showGlobalFloatingRightBanner'
  | 'showGlobalFloatingLeftBanner'
  | 'showGlobalFloatingBottomRightBannerTextButton'
  | 'showGlobalInlineProfileMiddleBanner'
  | 'showStoreSearchInlineSearchBottomBannerTextButton'
  | 'showStoreAppInlineAppFormBottomRight'
  | 'showLaunchpadDashboardInlineTopRightBanner'
  | 'showLaunchpadMainInlineMiddleBanner'
  | 'showStakingMainInlineListBottomBanner';

export type AdsFeatureToggles = {
  [K in AdsToggleKey]?: boolean;
};

export interface NormalizedFeatureToggles extends AdsFeatureToggles {
  /**
   * Toggle Refer a Friend page
   */
  // referFriend?: boolean;
  /**
   * Enable snapshot validation instead old validation flow
   */
  appValidation?: boolean;
  /**
   * Enable login options for mobile Magic Boost page
   */
  mobileLoginOptionsMagicBoost?: boolean;
  /**
   * Enable emailing on Refer a Friend page
   */
  // referFriendEmailing?: boolean;
  /**
   * Enable access to the hot offers section, namely - the in the site header, the URL itself is always open
   */
  // hotOffers?: boolean;
  /**
   * Enable to show sort binance/regular hot offers
   */
  binanceHotOffers?: boolean;
  /**
   * Enable One signal notification
   */
  // notification?: boolean;
  /**
   * Enable OAuth 2.0 sing in/up option
   */
  'oauth2.0'?: boolean;
  /**
   * Enable passwordless auth
   */
  passwordlessAuth?: boolean;
  /**
   * Enable Subscribe Button in MagicID auth
   */
  forcePushSubscribeButton?: boolean;
  /**
   * Disable possibility of authorization on the mobile version of the site
   */
  // mobileNoAuth?: boolean;
  /**
   * Disable access to the mobile version of the site if there is no IS_DEVELOPMENT in the configuration or if it is false
   */
  // mobileTemp?: boolean;
  /**
   * Enable Binance rewards
   */
  binanceRewards?: boolean;
  /**
   * Enable membership features
   */
  membership?: boolean;
  membershipFreePlusTier?: boolean;
  /**
   * Enable ability to log in with Metamask wallet
   */
  metamaskWallet?: boolean;
  /**
   * Enable ability to log in with Trust wallet
   */
  trustWallet?: boolean;
  /**
   * Enable ability to log in with OKX wallet
   */
  okxWallet?: boolean;
  /**
   * Toggle Phantom wallet across the platform
   */
  displayPhantomWallet?: boolean;
  /**
   * Enable ability to log in with Wallet Connect
   */
  walletConnect?: boolean;

  // Note: May be in CMS or deprecated
  // basicAuth?: boolean; // Enabled - allow user to login/signup with email (aka Basic Auth). Button shows in Auth Popup
  // sendImmediately?: boolean; // CRM. Option to play with notifications sending, that will ignore user's time zone and will deliver notification right away
  // easterEggsHunt?: boolean; // Easter eggs hunt campaign. Integration with Galxe.
  // onlyDesktopHasWalletAuth?: boolean; // Toggle showing wallet login buttons on mobile
  /**
   * Show Twitter verification in MagicID settings.
   */
  showTwitterVerify?: boolean;
  /**
   * Hides Discord verification in MagicID settings.
   */
  hideDiscordVerify?: boolean;
  /**
   * Hides Telegram verification in MagicID settings.
   */
  hideTelegramVerify?: boolean;
  /**
   * Hides Telegram auth button.
   */
  telegramLogin?: boolean;
  /**
   * Hides ui that shows data about karma rewards guide
   */
  // karmaRewardsGuide?: boolean;
  /**
   * Toggle /stories route
   */
  // stories?: boolean;
  /**
   * Toggle sharing button for hot offers
   */
  hotOffersSharing?: boolean;
  /**
   * Toggle internal voting validation
   */
  internalValidationVote?: boolean;
  /**
   * Toggle for developer campaigns related routes
   */
  developerCampaigns?: boolean;
  /**
   * Toggle for Magic Board related routes
   */
  magicBoard?: boolean;
  /**
   * Toggle for campaign review in developer area
   */
  campaignReview?: boolean;
  /**
   * Toggle for humanity verification
   */
  humanityVerification?: boolean;
  /**
   * Toggle for perks feature
   */
  perks?: boolean;
  /**
   * Mobile hide tooltip
   */
  mobileHideTooltip?: boolean;
  /**
   * Mobile hide socialFooterIcon
   */
  mobileHideSocialFooter?: boolean;
  /**
   * Enables header banner
   */
  usdtClaimOrHoldHeaderBanner?: boolean;
  /**
   * If it’s OFF the current withdrawal (Binance Gift cards) should be invoked by click on the withdrawal button. Otherwise, token withdrawal should be run.
   */
  tokenKarmaWithdrawal?: boolean;
  /**
   * Toggle 'Share Review' option in ReviewFlag
   */
  reviewFlagShare?: boolean;
  /**
   * Toggle 'Report Review' option in ReviewFlag
   */
  reviewFlagReport?: boolean;
  /**
   * Auth user tour
   */
  storeTour?: boolean;
  redNotificationBanner?: boolean;
  greenNotificationBanner?: boolean;
  gradientNotificationBanner?: boolean;
  /**
   * Rating widget block
   */
  ratingWidget?: boolean;
  /**
   * Block Premium Tiers while trying to upgrade to it
   */
  blockPremiumTiers?: boolean;
  /**
   * Allow users to change the tier not only on desktop
   */
  allowTierChangeOnMobile?: boolean;
  /**
   * Face recognition check
   */
  livenessVerification?: boolean;
  /**
   * Staking nav button
   */
  staking?: boolean;
  /**
   * Enables feedback buttons (yes, no, funny) and stats under app reviews and trust reviews
   */
  reviewFeedback?: boolean;
  /**
   * OneSignal notification
   */
  notification?: boolean;
  /**
   * Enables discord authorization
   */
  discordAuth?: boolean;
  /**
   * Enabled passkey
   */
  passkeyAuth?: boolean;
  /**
   * Enables login option with TON connect
   */
  tonConnectLogin?: boolean;
  /**
   * Show banxa link where user can get sqr tokens
   */
  banxaSqrTokenSource?: boolean;
  /**
   * Swap sandbox and prod url banxa
   */
  banxaSqrTokenSourceProdMode?: boolean;
  xAuth?: boolean;
  /**
   * Show swapper route
   */
  swapper?: boolean;
  /**
   * Show swapper links to its page
   */
  swapperLinks?: boolean;
  /**
   * Enables connecting TON wallet to magicid through settings
   */
  tonConnect?: boolean;
  /**
   * Enables Launchpad link in nav bars
   */
  launchpad?: boolean;
  /**
   * Toggles currency from SQR to SQRp in membership dashboard for Daily Rewards card
   */
  dailyRewardSQRp?: boolean;
  /**
   * When enabled pow offer form will be reset before user can retry resubmit
   */
  powOfferResetRejected?: boolean;
  /**
   * Enables reward pool pages and nav
   */
  rewardPools?: boolean;
  /**
   * Enables ad for use
   */
  enabledAdForUser?: boolean;
  enabledToggleAdForUser?: boolean;
  showStickyBanner?: boolean;
  showBannerAd?: boolean;
  showHeaderAd?: boolean;
  showStickySideBanner?: boolean;
  showMobileBottomBanner?: boolean;
  /**
   * Enables usdt deposit button
   */
  showUsdtDepositButton?: boolean;
  /**
   * Enables gas station functional
   */
  gasStation?: boolean;
  /**
   * Specifies whether the Magic Boost app is displayed on the Home Page and in the dropdown menu
   * of applications. Unless enabled, all links leading to /magic-boost/* will be redirected
   * to the Home Page.
   */
  enableMagicBoostApp?: boolean;
  /**
   * Enables Save My Spot button on sale pool card
   */
  showSaveMySpotButton?: boolean;
  /**
   * Enable ai assistent
   */
  aiAssistant?: boolean;
  /**
   * Enable web3 raffle
   */
  showWeb3Raffle?: boolean;
  /**
   * Enable the Telegram Mini Apps page in Store-web
   */
  showTelegramMiniAppsPage?: boolean;
  /**
   * Enable the Memes page in Store-web
   */
  showMemesPage?: boolean;
  /**
   * Enable the Ai-agent page in Store-web
   */
  showAiAgentsPage?: boolean;
}

export const normalizeFeatureToggle = (data: FeatureToggleList): NormalizedFeatureToggles => {
  return data?.rows?.reduce<NormalizedFeatureToggles>((acc, row) => {
    return {
      [row.key]: row.enabled,
      ...acc,
    };
  }, {});
};
